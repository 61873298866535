export default {
  name: 'computeTaxPayable',
  props: ['searchValue'],
  watch: {},
  components: {},
  data() {
    return {
      outputTaxData: [],
      outputTaxFields: [
        {
          key: 'tax_head',
          label: 'Tax Head',
          class: 'text-center'
        },
        {
          key: 'fcm',
          label: 'FCM',
          class: 'text-center'
        },
        {
          key: 'rcm',
          label: 'RCM',
          class: 'text-center'
        },
        {
          key: 'total',
          label: 'Total',
          class: 'text-center'
        }
      ],
      inputTaxData: [],
      inputTaxFields: [
        {
          key: 'tax_head',
          label: 'Tax Head',
          class: 'text-center'
        },
        {
          key: 'fcm',
          label: 'FCM',
          class: 'text-center'
        },
        {
          key: 'rcm',
          label: 'RCM',
          class: 'text-center'
        },
        {
          key: 'total',
          label: 'Total',
          class: 'text-center'
        }
      ],
      inputTaxLiabilityData: [],
      inputTaxLiabilityFields: [
        {
          key: 'tax_head',
          label: 'Tax Head',
          class: 'text-center'
        },
        {
          key: 'fcm',
          label: 'PAYABLE-FCM',
          class: 'text-center'
        },
        {
          key: 'itc_igst',
          label: 'ITC-IGST',
          class: 'text-center'
        },
        {
          key: 'itc_cgst',
          label: 'ITC-CGST',
          class: 'text-center'
        },
        {
          key: 'itc_sgst',
          label: 'ITC-SGST',
          class: 'text-center'
        },
        {
          key: 'total',
          label: 'TOTAL',
          class: 'text-center'
        }
      ],
      outputTaxLiabilityData: [],
      outputTaxLiabilityFields: [
        {
          key: 'tax_head',
          label: 'Tax Head',
          class: 'text-center'
        },
        {
          key: 'fcm',
          label: 'FCM',
          class: 'text-center'
        },
        {
          key: 'rcm',
          label: 'RCM',
          class: 'text-center'
        },
        {
          key: 'total',
          label: 'Total',
          class: 'text-center'
        }
      ],
      inputLedgerData: [],
      inputLedgerFields: [
        {
          key: 'tax_head',
          label: 'Tax Head',
          class: 'text-center'
        },
        {
          key: 'itc_amount',
          label: 'ITC Amount',
          class: 'text-center'
        }
      ],
      legalEntity: null,
      financialYear: null,
      gstNum: null,
      period: null,
      cgstAmt: null,
      sgstAmt: null,
      igstAmt: null,
      totalAmt: null,
      ecl:'Opening Balance'
    };
  },
  mounted() {
    // if (this.searchValue.le_id.length) {
    this.legalEntity = this.searchValue.le_name;
    this.financialYear = this.searchValue.financialYear;
    this.gstNum = this.searchValue.gst_number;
    this.period = this.searchValue.period;
    this.getCompTaxPayableDtls();
    this.getUpdateTaxDtls();
    // }
  },
  methods: {
    getCompTaxPayableDtls() {
      const payload = {
        le_id: this.searchValue.le_id,
        gst_number: this.searchValue.gst_number,
        period: this.searchValue.period
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/getCompTaxPayableDtls', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.outputTaxData = response.data.data.data1.data;
            this.inputTaxData = response.data.data.data2.data;
            this.inputTaxLiabilityData = response.data.data.data3.data;
            this.outputTaxLiabilityData = response.data.data.data4.data;
            this.inputLedgerData = response.data.data.data5.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUpdateTaxDtls() {
      const payload = {
        le_id: this.searchValue.le_id,
        gst_number: this.searchValue.gst_number,
        period: this.searchValue.period
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/getUpdateTaxDtls', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            this.cgstAmt = result[0].cgst;
            this.sgstAmt = result[0].sgst;
            this.igstAmt = result[0].igst;
            this.totalAmt = result[0].total;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateTaxDtls() {
      const payload = {
        leid: +this.searchValue.le_id,
        gst_no: this.searchValue.gst_number,
        period_name: this.searchValue.period,
        cgst: +this.cgstAmt,
        sgst: +this.sgstAmt,
        igst: +this.igstAmt
      };
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/updateTaxDtls', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.getUpdateTaxDtls();
            this.getCompTaxPayableDtls();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
