import commonHelper from '@/app/utility/common.helper.utility';
import taxOutward from './taxOutward';
import supplieNotified from './supplieNotified';
import interStateSupplie from './interStateSupplie';
import eligibleItc from './eligibleItc';
import exempt from './exempt';
import interestLateFee from './interestLateFee';
import paymentTax from './paymentTax';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import { ModelSelect } from 'vue-search-select';
import gstrSummaryData from './gstrSummaryData';
import computeTaxPayable from './computeTaxPayable';
export default {
  components: {
    ModelSelect,
    gstrSummaryData,
    taxOutward,
    supplieNotified,
    interStateSupplie,
    eligibleItc,
    exempt,
    interestLateFee,
    paymentTax,
    computeTaxPayable
  },
  data() {
    return {
      loader: false,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      totalRows: null,
      gstrSummaryData: [],
      defaultValue: {
        value: null,
        text: null
      },
      legalEntity: {
        value: null,
        text: null
      },
      financialYear: {
        value: null,
        text: null
      },
      financialYearList: [],
      selectedGstnumber: {
        value: null,
        text: null
      },
      selectedPeriodFrom: {
        value: null,
        text: null
      },
      periodType: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      showgstr3BSummaryModal: false,
      tab: null,
      tabDetail: null,
      searchValue: {
        le_id: null,
        le_name: null,
        gst_number: null,
        period: null,
        financialYear: null,
        filed_flag: null
      },
      filedFlag: false,
      editMode: false,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      showComputeTaxPayableModal: false
    };
  },
  validations: {
    legalEntity: {
      text: { required }
    },
    selectedGstnumber: {
      text: { required }
    },
    financialYear: {
      text: { required }
    },
    selectedPeriodFrom: {
      text: { required }
    }
  },
  mounted() {
    this.getFinancialYearList();
  },
  methods: {
    checkIndex(index, item) {
      switch (index) {
        case 0:
          this.showHideModalComponent(true, 'taxOutward', item);
          break;
        case 1:
          this.showHideModalComponent(true, 'supplieNotified', item);
          break;
        case 2:
          this.showHideModalComponent(true, 'interStateSupplie', item);
          break;
        case 3:
          this.showHideModalComponent(true, 'eligibleItc', item);
          break;
        case 4:
          this.showHideModalComponent(true, 'exempt', item);
          break;
        case 5:
          this.showHideModalComponent(true, 'interestLateFee', item);
          break;
        default:
          this.showHideModalComponent(true, 'paymentTax', item);
      }
    },
    setChildName(tab, item) {
      if (tab === 'taxOutward') {
        return (this.childName = item.heading);
      } else if (tab === 'supplieNotified') {
        return (this.childName = item.heading);
      } else if (tab === 'interStateSupplie') {
        return (this.childName = item.heading);
      } else if (tab === 'eligibleItc') {
        return (this.childName = item.heading);
      } else if (tab === 'exempt') {
        return (this.childName = item.heading);
      } else if (tab === 'interestLateFee') {
        return (this.childName = item.heading);
      } else if (tab === 'paymentTax') {
        return (this.childName = item.heading);
      }
    },
    showHideModalComponent(flag, component, item) {
      this.showgstr3BSummaryModal = flag;
      this.tab = component;
      this.tabDetail = item;
      this.searchValue.le_id = this.legalEntity.value;
      this.searchValue.gst_number = this.selectedGstnumber.value;
      this.searchValue.period = this.selectedPeriodFrom.value;
      this.searchValue.filed_flag = this.filedFlag;
      this.setChildName(this.tab, item);
    },
    getFinancialYearList() {
      this.loader = true;
      this.$store
        .dispatch('gstrThreeBSummary/getFinancialYearList')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            const finYearList = results.map(type => {
              return {
                value: type.fy_display,
                text: type.fy_display
              };
            });
            this.financialYearList = finYearList;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getGstrThreeBSummaryTabData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          le_id: this.legalEntity.value,
          fin_year: this.financialYear.value,
          gst_number: this.selectedGstnumber.value,
          period: this.selectedPeriodFrom.value
        };
        this.loader = true;
        this.$store
          .dispatch('gstrThreeBSummary/getGstrThreeBSummaryTabData', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.gstrSummaryData = response.data.data;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getKeyAndValue(obj) {
      let rawHtml = '';

      for (const key in obj) {
        rawHtml += `<b-card-text style="text-align: right">${key}<br />${obj[key]}</b-card-text>`;
      }
      return rawHtml;
    },
    openValueSetModal(vsetCode, periodType) {
      this.periodType = periodType;
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.parent_value_set_id = null;
      }

      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.org_id,
          text: item.org_name
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.selectedGstnumber = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    setSelectedCalendar(option) {
      this.selectedCalendar = option;
      this.getFinantialYearList(option.value);
    },
    clear() {
      this.legalEntity = this.defaultValue;
      this.selectedGstnumber = this.defaultValue;
      this.selectedPeriodFrom = this.defaultValue;
      this.financialYear = this.defaultValue;
      this.parent_value_set_id = null;
      this.gstrSummaryData = [];
    },
    clearVsetValues(vsetCode, periodType) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN) {
        this.selectedGstnumber = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (periodType === 'periodFrom') {
          this.selectedPeriodFrom = this.defaultValue;
        }
      }
    },
    toggle() {
      this.filedFlag != this.filedFlag;
    },
    openComputeTaxPayableModal() {
      if (
        this.legalEntity.text &&
        this.financialYear.text &&
        this.selectedGstnumber.text &&
        this.selectedPeriodFrom.text
      ) {
        this.showHideComputeTaxPayableModal(true);
      } else {
        this.$bvToast.toast('Please select LOV first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    showHideComputeTaxPayableModal(flag) {
      (this.searchValue = {
        le_id: this.legalEntity.value,
        le_name: this.legalEntity.text,
        gst_number: this.selectedGstnumber.text,
        period: this.selectedPeriodFrom.text,
        financialYear: this.financialYear.text
      }),
        (this.showComputeTaxPayableModal = flag);
    }
  }
};
